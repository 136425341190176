import React, { useContext } from "react";
import * as S from "./OfferContainerV1.styles";
import Context from "../../context/Context";

const OfferContainerV1 = ({ children, offerIndex }) => {
  const { currentIndex } = useContext(Context);

  return offerIndex === currentIndex ? (
    <S.Container offerIndex={offerIndex}>
      {children ? children : `-- no children --`}
    </S.Container>
  ) : null;
};

export default OfferContainerV1;
